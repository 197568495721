<template>
  <div>
    <van-nav-bar
      :title="company"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="worthtest">
      <div
        class="paading-min"
        v-for="(item, index) in subjectList"
        :key="index"
        v-if="num == index"
      >
        <div>{{ num + 1 }}/{{ subjectList.length }}</div>
        <div class="xhtm">
          <div class="timu">{{ item.subject }}</div>
          <div
            class="xuanxiangcenter"
            v-for="(option, optionIndex) in item.item"
            :key="optionIndex"
          >
            <div
              class="xuanxiang"
              :class="{
                xuanxiangactive: option.active,
                disabled: option.disabled,
              }"
              @click="handleOptionClick(item, option)"
            >
              {{ option.name }}
            </div>
          </div>
        </div>
        <div class="btn-center">
          <button class="shangti" v-if="num > 0" @click="uptimu">上一题</button>
        </div>
      </div>

      <button class="tijiao" v-show="showbtn" @click="submit">提交</button>
      <div class="tanchuang" v-show="showtanchuang">
        <div class="tanchuanghezi">
          <div>已完成</div>
          <button @click="totestresult">查看结果</button>
          <button @click="gohome()">返回主页</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { startAssess, assessSave, setComplated } from "../../api/login";
export default {
  data() {
    return {
      showbtn: false,
      showtanchuang: false,
      subjectList: [],
      num: 0,
      daan: [],
      id: "",
      reportId: "",
      unitId: window.localStorage.getItem("unitId"), //公司id
      company: "", //公司
    };
  },
  created() {},
  mounted() {
    if (this.unitId == "undefined") {
      this.company = "高情商训练营";
    }
    if (this.unitId == "sys1818") {
      this.company = "情绪价值训练营";
    }
    if (this.unitId == "1397") {
      this.company = "高情商训练营";
    }
    if (this.$route.query.item) {
      this.id = this.$route.query.item.id;
      this.getstartAssess();
      console.log(this.id);
    }

    //从再测一次拿到的ID
    if (this.$route.query.subId) {
      this.id = this.$route.query.subId;
      this.getstartAssess();
      console.log(this.id);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gohome() {
      this.$router.push("/");
    },
    getstartAssess() {
      let data = {
        userId: window.localStorage.getItem("userId"),
      };

      //获取题目
      startAssess(data).then((res) => {
        console.log(res.data.subjectList);
        this.subjectList = res.data.subjectList;
        this.reportId = res.data.reportId;
      });
    },
    handleOptionClick(question, clickedOption) {
      question.item.forEach((option) => {
        option.active = false;
      });

      clickedOption.active = true;

      console.log(clickedOption.id);
      this.daan[this.num] =
        "lb" +
        clickedOption.lbassessment_id +
        "-" +
        clickedOption.subjectid +
        "=" +
        clickedOption.id;

      if (this.num < this.subjectList.length - 1) {
        console.log(this.daan);
        this.num++;
        this.num--;
        setTimeout(() => {
          this.num++;
        }, 100);
      } else {
        this.showbtn = true;
        this.num++;
        setTimeout(() => {
          this.num--;
        }, 10);
      }
    },
    uptimu() {
      this.num--;
    },
    submit() {
      Toast.loading({ message: "提交中", forbidClick: true, duration: 3000 });
      console.log(this.daan);
      let data = {
        userId: window.localStorage.getItem("userId"),
        answer: this.daan,
        // reportId: this.reportId,
        subid: window.localStorage.getItem("subid"),
      };
      assessSave(data).then((res) => {
        // Toast.success("提交成功");
        Toast.clear();
        this.showtanchuang = true;
        this.onsetComplated();
        this.subjectId = res.data.subjectId;
      });
    },

    //点击完成
    onsetComplated() {
      let data = {
        id: window.localStorage.getItem("subid"),
        userId: window.localStorage.getItem("userId"),
      };
      setComplated(data).then((res) => {});
    },

    totestresult() {
      console.log("查看报告拿到的id", this.subjectId);
      this.$router.push({
        path: "/test/testresult",
        query: { id: this.subjectId },
      });
    },
  },
};
</script>

<style lang="less">
.worthtest {
  background-color: #f4fafe;
  // min-height: 100vh;
  box-sizing: border-box;
  //   padding: 20px;
  .paading-min {
    padding: 20px;
  }
  .timu {
    font-size: 13px;
    font-weight: 500;
    color: #000025;
    line-height: 18px;
    letter-spacing: 1px;
    margin-top: 20px;
  }
  .xuanxiangcenter {
    .xuanxiang {
      margin: 0 auto;
      margin-top: 32px;
      width: 238px;
      // height: 40px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
      border-radius: 8px;
      font-size: 13px;
      font-weight: 500;
      color: #000025;
      // line-height: 40px;
      letter-spacing: 1px;
      // box-sizing: border-box;
      padding: 10px 15px;
    }
    .xuanxiangactive {
      background-color: #e4effb;
      border: 1px solid #72acf4;
      color: #72acf4;
    }
  }
  .btn-center {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .shangti {
    border-radius: 50%;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    width: 96px;
    height: 96px;
    border: 0;
    background-color: #fff;
  }
  .tijiao {
    width: 100%;
    height: 72px;
    border: 0;
    line-height: 72px;
    position: absolute;
    bottom: 0;
    background-color: #72acf4;
    color: #fff;
  }
  .tanchuang {
    height: 100vh;
    width: 100%;
    top: 0;
    background: rgba(185, 184, 184, 0.5);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .tanchuanghezi {
      width: 248px;
      height: 222px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(171, 171, 171, 0.49),
        inset 1px 1px 0px 0px #ffffff;
      border-radius: 16px;
      backdrop-filter: blur(3px);
      box-sizing: border-box;
      padding: 40px;
      text-align: center;

      button {
        width: 164px;
        height: 36px;
        background: #72acf4;
        border-radius: 100px;
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        line-height: 21px;
        border: 0;
        margin-top: 20px;
      }
    }
  }
}
</style>
